import { createTheme } from '@mui/material/styles';

const borderRadius = 8;

export const customTheme = createTheme({
  palette: {
    mode: 'dark',
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    primary: {
      light: '#76B0F1',
      main: '#2991e3',
      dark: '#103996',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#84A9FF',
      main: '#3366FF',
      dark: '#1939B7',
      contrastText: '#FFFFFF',
    },
    info: {
      light: '#61F3F3',
      main: '#00B8D9',
      dark: '#006C9C',
      contrastText: '#FFFFFF',
    },
    success: {
      light: '#86E8AB',
      main: '#36B37E',
      dark: '#1B806A',
      contrastText: '#FFFFFF',
    },
    warning: {
      light: '#FFD666',
      main: '#FFAB00',
      dark: '#B76E00',
      contrastText: '#212B36',
    },
    error: {
      light: '#FFAC82',
      main: '#FF5630',
      dark: '#B71D18',
      contrastText: '#FFFFFF',
    },
    divider: 'rgba(145, 158, 171, 0.24)',
    action: {
      hover: 'rgba(145, 158, 171, 0.08)',
      selected: 'rgba(145, 158, 171, 0.16)',
      disabled: 'rgba(145, 158, 171, 0.8)',
      disabledBackground: 'rgba(145, 158, 171, 0.24)',
      focus: 'rgba(145, 158, 171, 0.24)',
      hoverOpacity: 0.08,
      disabledOpacity: 0.48,
      active: '#919EAB',
      selectedOpacity: 0.16,
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
    text: {
      primary: '#eee',
      secondary: '#919EAB',
      disabled: '#637381',
    },
    background: {
      paper: '#1d3353',
      default: '#161C24',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  shape: {
    borderRadius,
  },
  spacing: 8,
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderTopLeftRadius: borderRadius,
          borderTopRightRadius: borderRadius,
          textTransform: 'none',
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.light,
          borderTopLeftRadius: borderRadius,
          borderTopRightRadius: borderRadius,
        }),
        scrollButtons: {
          borderRadius,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: borderRadius,
          '& > .MuiInputLabel-root': {
            '&.Mui-focused': {
              color: theme.palette.text.secondary,
            },
            marginLeft: ownerState.InputProps?.startAdornment
              ? theme.spacing(4)
              : 'auto',
          },
        }),
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: borderRadius,
          '& .MuiInputAdornment-root': {
            marginTop: '0 !important',
          },
          '& > input': {
            borderRadius: borderRadius,
          },
        }),
        error: ({ theme }) => ({
          border: `2px solid ${theme.palette.error.main}`,
        }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        shrink: true,
      },
      styleOverrides: {
        root: {
          '& .MuiInputLabel-asterisk': {
            display: 'none',
          },
          ':not(&.Mui-required)': {
            marginLeft: 0,
            '&::after': {
              content: '" (Optional)"',
            },
            '& + .MuiOutlinedInput-root': {
              '& fieldset span::after': {
                content: '" (Optional)"',
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          boxShadow:
            'rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        body: {
          'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus':
            {
              border: 'inherit',
              color: theme.palette.text.primary,
              '-webkit-text-fill-color': theme.palette.text.primary,
              '-webkit-box-shadow': 'none !important',
              transition: 'background-color 5000s ease-in-out 0s',
            },
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/Background3.png)`,
          backgroundRepeat: 'repeat-y',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
      }),
    },
  },
});
