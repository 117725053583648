import { AnimatePresence } from 'framer-motion';
import { lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Layout } from './components/layout';

const PageNotFound = lazy(() => import('./pages/PageNotFound'));
const LandingPage = lazy(() => import('./pages/landing/MainPage'));
const MainStepsPage = lazy(() => import('./pages/mainsteps/MainStepsPage'));
const PurchasePage = lazy(() => import('./pages/purchase/PurchasePage'));

export function AppRoutes() {
  const location = useLocation();
  /*exitBeforeEnter*/
  return (
    <AnimatePresence mode='wait'>
      <Routes location={location} key={location.pathname}>
        <Route path={''} element={<Layout />}>
          <Route path={'/'} element={<LandingPage />} />
          <Route path={'/index'} element={<LandingPage />} />
          <Route path={'/purchase/:leadId'} element={<PurchasePage />} />
          <Route path={'/steps'} element={<MainStepsPage />} />
        </Route>

        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
