import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { RecoilRoot } from 'recoil';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';

import { customTheme } from './theme/customTheme';

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <RecoilRoot>
        <CssBaseline />
        <SnackbarProvider
          hideIconVariant={false}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </SnackbarProvider>
      </RecoilRoot>
    </ThemeProvider>
  );
}

export default App;
