import { Box, styled, Container, CircularProgress } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Suspense } from 'react';
import { motion } from 'framer-motion';

export function Copyright() {
  return (
    <Box display='flex' justifyContent={'center'} p={3}>
      <Box maxWidth={320}>
        <img
          src={`${process.env.PUBLIC_URL}/images/RenatusLogo.png`}
          alt={'logo'}
          width={'100%'}
        />
      </Box>
    </Box>
  );
}

const StyledMain = styled(Box)(({ theme }) => ({
  position: 'relative',
  flexGrow: 1,
  height: '100vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr auto',
  gridTemplateAreas: '"main" "footer"',
  '& main': {
    gridArea: 'main',
  },
  '& footer': {
    gridArea: 'footer',
    height: 180,
  },
}));

function Loading() {
  return (
    <Box
      display={'flex'}
      rowGap={2}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      height={'100%'}
    >
      <CircularProgress />
      <div>Loading...</div>
    </Box>
  );
}

export function Layout() {
  return (
    <StyledMain>
      <Box component='main'>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </Box>
      <Box component='footer'>
        <Copyright />
      </Box>
    </StyledMain>
  );
}

export function Page({ maxWidth, children }: any) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.6 }}
    >
      <Container maxWidth={maxWidth || 'md'} sx={{ p: 1, width: '100vw' }}>
        {children}
      </Container>
    </motion.div>
  );
}
